<template>

  <div class="menuBar">
    <b-navbar toggleable="lg" type="dark" variant="info" class="navbar-custom fixed-top bg-info" :class="{change_color: scrollPosition > 170, chng_color: scrollPosition < 170   }">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-navbar-brand id="logo-desktop" :to="{path:'/'}"><img src="../assets/logo.png" alt=""></b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto justify-content-around w-100 navbarToCenter">
          <b-nav-item :to="{path:'/blue-caves-croatia'}">Group Boat Tours</b-nav-item>
          <b-nav-item :to="{path:'/hvar-boat-tours-top-rated-adventures'}">Private Boat Tours</b-nav-item>
          <b-nav-item :to="{path:'/hvar-boat-hire-adriatics-hidden-gems'}">Boat Rentals</b-nav-item>
          <b-nav-item :to="{path:'/from-split-to-Hvar-transfer'}">Boat Transfers</b-nav-item>
          <b-nav-item :to="{path:'/hvar-weather'}">Weather</b-nav-item>
          <b-nav-item :to="{path:'/frequently-asked-questions'}">FAQ</b-nav-item>
          <b-nav-item :to="{path:'/contact'}">Contact</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>
export default {
  data() {
    return{
    scrollPosition: null
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}
</script>
<style scoped>
.change_color {
  background-color: rgba(41, 59, 95, 0.9)!important;
}
.chng_color{
  background-color: rgba(3, 37, 108, .1)!important ;
}
@media (max-width: 480px) {
  .chng_color {
    background-color: rgba(41, 59, 95, 0.9) !important;

  }

}
</style>