import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAgile from 'vue-agile'
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Vuex from 'vuex'


delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.use(VueSimpleAccordion, {

});
Vue.use(Vuex)
Vue.use(VueAgile)
Vue.config.productionTip = false
Vue.use(VModal,{
  dynamicDefaults:{
    styles:'background-color:#293b5f '

  }
})
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  render: h => h(App),

}).$mount('#app')
