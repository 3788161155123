import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import (/* webpackChunkName: "home" */ '../views/Home')

  },
  {
    path: '/blue-caves-croatia',
    name: 'GroupToursDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "groupTourDetails" */ '../views/GroupToursDetails')
  },
  {
    path: '/hvar-boat-tours-top-rated-adventures',
    name: 'BoatTours',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "boatTours" */ '../views/BoatTours')
  },

  {
    path: '/hvar-boat-hire-adriatics-hidden-gems',
    name: 'BoatRentals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "boatRentals" */ '../views/BoatRentals')
  },
  {
    path: '/from-split-to-Hvar-transfer',
    name: 'BoatTransfers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "boatTransfers" */ '../views/BoatTransfers')
  },

  {
    path: '/hvar-weather',
    name: 'Weather',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "weather" */ '../views/Weather')
  },
  {
    path: '/frequently-asked-questions',
    name: 'Faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact')
  },
  {
    path: '/:id',
    meta: {
      sitemap: {
        slugs: [
          'blue-cave-tour-from-hvar',
          'best-beaches-in-hvar-private',
          'zlatni-rat-brac-island',
          'boat-party-tour'

        ]
      }
    },
    name: 'details',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivateToursDetails')
  },
  {
    path: '/rent-a-boat-hvar/:id',
    meta: {
      sitemap: {
        slugs: [
          '/rent-a-boat-hvar/speed-boat-hire-hvar-flyer747',
          '/rent-a-boat-hvar/luxury-boat-hire-hvar-tornado38',


        ]
      }
    },
    name: 'rentals',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BoatRentDetails')
  },
]

const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

})

export default router
