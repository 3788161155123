<template>
  <div id="app">
    <Navigation/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="css" scoped>
@import "../src/style/style.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700&display=swap');

@font-face {
  font-family: 'Futura';
  src: local('Futura Regular'), local('Futura-Regular'),
  url('../src/fonts/FuturaLT-Heavy.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Futura';
  src: local('Futura Regular'), local('Futura-Regular'),
  url('../src/fonts/FuturaLT-Light.woff') format('woff');

  font-weight: 300;
  font-style: normal;
}



</style>
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Navigation}
}
</script>

